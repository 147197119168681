import { RateStrategyCategory } from '../../__enums__/RateStrategyCategory';

export const configuration = {
  addenda: {
    delete: 'Supprimer l’addenda',
    deleteMessage: 'Voulez-vous vraiment supprimer cet addenda\u00a0?',
    edit: 'Modifier l’addenda',
    field: {
      automatic: 'Addenda automatique',
      templateEn: 'Description EN',
      templateFr: 'Description FR',
      kinds: 'Types',
      natureOfWorks: 'Natures de travail',
      natureOfWorkSubCategory: 'Catégories de nature du travail',
      specialSituation: 'Situations particulières',
      specialProjects: 'Projets spéciaux',
      kind: 'Type',
      title: 'Titre',
      titleEn: 'Titre EN',
      titleFr: 'Titre FR',
    },
    information: 'Information',
    conditions: 'Conditions',
    new: 'Nouvel addenda',
  },
  configuration: 'Configuration',
  competitors: {
    title: 'Competitors',
    name: 'Nom du compétiteur',
    placeholder: 'Entrez le nom du compétiteur',
  },
  enums: {
    billingCode: {
      code: 'Code',
      description: 'Description',
      labelEn: 'Étiquette anglais',
      labelFr: 'Étiquette français',
      title: 'Codes de facturation (accessoires)',
    },
    natureOfWork: {
      code: 'Code',
      defaultWorkSchedule: 'Horaire de travail par défaut',
      description: 'Description',
      title: 'Natures du travail',
      workSchedules: 'Horaires de travail',
      natureOfWorkSubCategories: 'Catégories',
    },
  },
  error: {
    errorDuringDelete: 'Une erreur est survenue lors de la suppression de l’élément',
    errorDuringSaveCompetitor: 'Une erreur est survenue lors de la sauvegarde des compétiteurs',
    errorDuringSaveBillingCodes: 'Une erreur est survenue lors de la sauvegarde des codes de facturation',
    errorDuringSaveNatureOfWorks: 'Une erreur est survenue lors de la sauvegarde de la nature du travail',
  },
  rateStrategies: {
    labelEn: 'Étiquette anglais',
    labelFr: 'Étiquette français',
    edit: 'Modifier la stratégie de taux',
    new: 'Nouvelle stratégie de taux',
    label: 'Étiquette',
    categories: 'Catégories',
    delete: 'Supprimer la stratégie de taux',
    deleteMessage: 'Voulez-vous vraiment supprimer cette stratégie de taux\u00a0?',
    transportCategoryKinds: 'Catégories de transport',
    rateStrategyCategory: {
      mainCrane: 'Grue principale',
      additionalCrane: 'Grue additionnelle',
      boomCounterweightTransport: 'Transport de flèche et contrepoids',
      flatbedTransport: 'Transport par fardier',
    } satisfies Record<RateStrategyCategory, string>,
  },
  section: {
    addenda: 'Addendas',
    craneCharts: 'Chartes de grues',
    competitors: 'Compétiteurs',
    enums: 'Énums.',
    rateStrategies: 'Stratégie de taux',
  },
};
