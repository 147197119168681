import { Alert, AlertProps, AlertTitle, Collapse, IconButton, useTheme } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, SyntheticEvent, useCallback } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export type AlertBannerState = {
  expanded: boolean;
  hidden: boolean;
};
export const ALERT_BANNER_DEFAULT_STATE = { expanded: false, hidden: false };

type Props = {
  state: AlertBannerState;
  setState: Dispatch<SetStateAction<AlertBannerState>>;
  title?: string;
  actions?: ReactNode[];
  collapsedSize?: string | number;
} & Omit<AlertProps, 'action'>;
export function AlertBanner({ state, setState, title, actions, collapsedSize, ...alertProps }: Props) {
  const theme = useTheme();

  const handleCollapseToggle = useCallback(
    (e: SyntheticEvent) => {
      setState((prev) => ({ ...prev, expanded: !prev.expanded }));
      e.stopPropagation();
    },
    [setState],
  );

  const handleClose = useCallback(
    (e: SyntheticEvent) => {
      alertProps.onClose?.(e);
      setState((prev) => ({ ...prev, hidden: true }));
      e.stopPropagation();
    },
    [alertProps, setState],
  );

  const handleClick = useCallback<NonNullable<AlertProps['onClick']>>(
    (e) => {
      handleCollapseToggle(e);
      alertProps.onClick?.(e);
    },
    [alertProps, handleCollapseToggle],
  );

  return (
    <Collapse in={!state.hidden}>
      <Alert
        {...alertProps}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          pb: 0,
          '& .MuiAlert-message': {
            pb: 0,
          },
          ...alertProps.sx,
        }}
        action={
          <>
            <IconButton onClick={handleCollapseToggle} color='inherit'>
              {state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
            <IconButton onClick={handleClose} color='inherit' {...alertProps.componentsProps?.closeButton}>
              <CloseIcon {...alertProps.componentsProps?.closeIcon} />
            </IconButton>
            {actions?.map((a) => a)}
          </>
        }>
        {title && (
          <AlertTitle
            sx={{
              [theme.breakpoints.down('sm')]: { fontSize: '0.9rem' },
            }}>
            {title}
          </AlertTitle>
        )}
        <Collapse
          orientation='vertical'
          in={state.expanded}
          collapsedSize={collapsedSize ?? '1.65lh'}
          sx={{
            '& .MuiCollapse-wrapperInner': {
              whiteSpace: 'pre-wrap',
            },
            ...(state.expanded
              ? {
                  pb: '0.5rem',
                }
              : {
                  mask: 'linear-gradient(to bottom, rgba(0,0,0,1) calc(100% - 1lh), rgba(0,0,0,0)) 0 0 / 100% 100% no-repeat',
                }),
          }}>
          {alertProps.children}
        </Collapse>
      </Alert>
    </Collapse>
  );
}
