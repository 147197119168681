/**
 * @generated SignedSource<<89c538b296d9ed3079d06d6d00cf202a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment$data = {
  readonly isAutomatic: boolean;
  readonly " $fragmentType": "AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment";
};
export type AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment$key = {
  readonly " $data"?: AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isAutomatic",
      "storageKey": null
    }
  ],
  "type": "AddendumTemplate",
  "abstractKey": null
};

(node as any).hash = "c8aa6fb0f109cc2ed7a7d42d7b60f5a6";

export default node;
