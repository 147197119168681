import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Box, Typography, TypographyProps, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback, useEffect } from 'react';
import { AssignedWorksite_Label_BaseFragment$key } from './__generated__/AssignedWorksite_Label_BaseFragment.graphql';
import { AssignedWorksite_Overrides_DenormalizableFragment$key } from './__generated__/AssignedWorksite_Overrides_DenormalizableFragment.graphql';
import { AssignedWorksite_Overrides_BaseFragment$key } from './__generated__/AssignedWorksite_Overrides_BaseFragment.graphql';
import {
  useFieldAssignedWorksiteRead,
  useFieldWorksiteOverridesContact,
  useFieldWorksiteOverridesLocation,
  useFieldWorksiteOverridesName,
  useFieldWorksiteOverridesPhoneNumber,
  useFieldWorksiteOverridesRequirements,
} from './fields/ProjectBaseFields';
import { AssignedWorksite_Overrides_ClientFragment$key } from './__generated__/AssignedWorksite_Overrides_ClientFragment.graphql';
import { AssignedWorksite_UseHasWorksiteInfoFragment$key } from './__generated__/AssignedWorksite_UseHasWorksiteInfoFragment.graphql';
import { ServiceCallKind } from '../__enums__/ServiceCallKind';
import { ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key } from './fields/__generated__/ProjectBaseFields_WorksiteRequirementsSuggestionsFragment.graphql';
import { createSharedStateKey, useSharedState } from '../common/utils/sharedState';
import { jobSharedStateContext, updatedAssignedWorksite$keysSharedStateKey } from './JobSharedState';
import { useOperations, useOperationsRelay } from '../AppSharedState';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import { useEffectEvent } from '../common/utils/effectUtils';
import { AssignedWorksiteQuery, AssignedWorksiteQuery$variables } from './__generated__/AssignedWorksiteQuery.graphql';
import { AssignedWorksiteFragment$key } from './__generated__/AssignedWorksiteFragment.graphql';
import { AssignedWorksite_ClientFragment$key } from './__generated__/AssignedWorksite_ClientFragment.graphql';
import { AssignedWorksite_useAssignedWorksiteFragment$key } from './__generated__/AssignedWorksite_useAssignedWorksiteFragment.graphql';
import { AssignedWorksite_useUpdateWorksiteFragment$key } from './__generated__/AssignedWorksite_useUpdateWorksiteFragment.graphql';
import {
  AssignedWorksite_useUpdateWorksiteQueryFragment$data,
  AssignedWorksite_useUpdateWorksiteQueryFragment$key,
} from './__generated__/AssignedWorksite_useUpdateWorksiteQueryFragment.graphql';

const UPDATE_ASSIGNED_WORKSITE_OPERATION_KEY = 'AssignedWorksite_UpdateAssignedWorksite';
export const useUpdateWorksite$SharedStateKey = createSharedStateKey<
  AssignedWorksite_useUpdateWorksiteQueryFragment$key | null | undefined
>(() => null);
export function useAssignedWorksite(
  $key: AssignedWorksite_useAssignedWorksiteFragment$key | null | undefined,
  disabled: boolean,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment AssignedWorksite_useAssignedWorksiteFragment on ProjectInternalBase {
        ...AssignedWorksite_useUpdateWorksiteFragment
        assignedWorksite {
          requirements {
            ...ProjectBaseFields_RequirementsFragment
          }
        }
        ...ProjectBaseFields_AssignedWorksiteFragment
        assignedWorksiteInfo {
          ...ProjectBaseFields_WorksiteOverrides_NameFragment
          ...ProjectBaseFields_WorksiteOverrides_LocationFragment
          ...ProjectBaseFields_WorksiteOverrides_ContactFragment
          ...ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment
          requirements {
            ...ProjectBaseFields_RequirementsFragment
          }
        }
      }
    `,
    $key,
  );

  const { assignedWorksite, assignedWorksiteIsDirty } = useFieldAssignedWorksiteRead($data);
  const [, setUpdatedAssignedWorksite$keys] = useSharedState(jobSharedStateContext, updatedAssignedWorksite$keysSharedStateKey);
  const [, setUseUpdateWorksite$keys] = useSharedState(jobSharedStateContext, useUpdateWorksite$SharedStateKey);
  const { setWorksiteOverridesName } = useFieldWorksiteOverridesName($data?.assignedWorksiteInfo, disabled, required);
  const { setWorksiteOverridesLocation } = useFieldWorksiteOverridesLocation($data?.assignedWorksiteInfo, null, disabled, required);
  const { setWorksiteOverridesRequirements } = useFieldWorksiteOverridesRequirements(
    $data?.assignedWorksiteInfo?.requirements,
    $data?.assignedWorksite?.requirements,
    disabled,
  );
  const { setWorksiteOverridesContact } = useFieldWorksiteOverridesContact($data?.assignedWorksiteInfo, disabled);
  const { setWorksiteOverridesPhoneNumber } = useFieldWorksiteOverridesPhoneNumber($data?.assignedWorksiteInfo, disabled);

  const fetchQuery = useOperationsRelay(UPDATE_ASSIGNED_WORKSITE_OPERATION_KEY);

  const createNewWorksite = useCallback(() => {
    setUpdatedAssignedWorksite$keys(null);
    setWorksiteOverridesName('');
    setWorksiteOverridesLocation({ address: '', placeToken: null });
    setWorksiteOverridesRequirements([]);
    setWorksiteOverridesContact('');
    setWorksiteOverridesPhoneNumber('');
  }, [
    setUpdatedAssignedWorksite$keys,
    setWorksiteOverridesName,
    setWorksiteOverridesLocation,
    setWorksiteOverridesRequirements,
    setWorksiteOverridesContact,
    setWorksiteOverridesPhoneNumber,
  ]);

  const [_, setSubscription] = useCancellableSubscription();

  const fetchUpdateWorksite = useEffectEvent((variables: AssignedWorksiteQuery$variables) => {
    const query = graphql`
      query AssignedWorksiteQuery($id: ID!) {
        assignedWorksite: node(id: $id) @required(action: THROW) {
          ... on Worksite {
            ...AssignedWorksite_useUpdateWorksiteQueryFragment
          }
        }
      }
    `;

    setSubscription(
      fetchQuery<AssignedWorksiteQuery>(query, variables, [(result) => setUseUpdateWorksite$keys(result.assignedWorksite)]).subscribe({}),
    );
  });

  useUpdateWorksite($data, disabled, required);

  useEffect(() => {
    if (assignedWorksite && assignedWorksiteIsDirty) {
      if (assignedWorksite.id === 'new') {
        createNewWorksite();
      } else {
        fetchUpdateWorksite({ id: assignedWorksite.id });
      }
    }
  }, [assignedWorksite, assignedWorksiteIsDirty, createNewWorksite, fetchUpdateWorksite]);
}

function useUpdateWorksite($key: AssignedWorksite_useUpdateWorksiteFragment$key | null | undefined, disabled: boolean, required: boolean) {
  const { endOperation } = useOperations(UPDATE_ASSIGNED_WORKSITE_OPERATION_KEY);

  const $data = useFragment(
    graphql`
      fragment AssignedWorksite_useUpdateWorksiteFragment on ProjectInternalBase {
        assignedWorksite {
          requirements {
            ...ProjectBaseFields_RequirementsFragment
          }
        }
        assignedWorksiteInfo {
          ...ProjectBaseFields_WorksiteOverrides_NameFragment
          ...ProjectBaseFields_WorksiteOverrides_LocationFragment
          ...ProjectBaseFields_WorksiteOverrides_ContactFragment
          ...ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment
          requirements {
            ...ProjectBaseFields_RequirementsFragment
          }
        }
      }
    `,
    $key,
  );

  const { setWorksiteOverridesName } = useFieldWorksiteOverridesName($data?.assignedWorksiteInfo, disabled, required);
  const { setWorksiteOverridesLocation } = useFieldWorksiteOverridesLocation($data?.assignedWorksiteInfo, null, disabled, required);
  const { setWorksiteOverridesRequirements } = useFieldWorksiteOverridesRequirements(
    $data?.assignedWorksiteInfo?.requirements,
    $data?.assignedWorksite?.requirements,
    disabled,
  );
  const { setWorksiteOverridesContact } = useFieldWorksiteOverridesContact($data?.assignedWorksiteInfo, disabled);
  const { setWorksiteOverridesPhoneNumber } = useFieldWorksiteOverridesPhoneNumber($data?.assignedWorksiteInfo, disabled);
  const [, setUpdatedAssignedWorksite$keys] = useSharedState(jobSharedStateContext, updatedAssignedWorksite$keysSharedStateKey);
  const [query$key] = useSharedState(jobSharedStateContext, useUpdateWorksite$SharedStateKey);

  const query$data = useFragment(
    graphql`
      fragment AssignedWorksite_useUpdateWorksiteQueryFragment on Worksite {
        ...AssignedWorksite_Label_BaseFragment
        ...AssignedWorksite_Overrides_BaseFragment
        ...AssignedWorksite_Overrides_DenormalizableFragment
        name
        location {
          address
          placeToken {
            id
            placeId
          }
        }
        requirements {
          id
          label
        }
        contact
        phoneNumber
      }
    `,
    query$key,
  );

  const updateWorksite = useEffectEvent((data: AssignedWorksite_useUpdateWorksiteQueryFragment$data) => {
    setUpdatedAssignedWorksite$keys({
      baseLabel$key: data,
      baseOverrides$key: data,
      denormalizableOverrides$key: data,
    });
    setWorksiteOverridesName(data.name ?? '');
    setWorksiteOverridesLocation(
      data.location
        ? {
            address: data.location.address,
            placeToken: {
              id: data.location.placeToken?.id ?? '',
              placeId: data.location.placeToken?.placeId ?? '',
            },
          }
        : { address: '', placeToken: null },
    );
    setWorksiteOverridesRequirements(data.requirements ?? []);
    setWorksiteOverridesContact(data.contact ?? '');
    setWorksiteOverridesPhoneNumber(data.phoneNumber ?? '');
    endOperation();
  });

  useEffect(() => {
    if (!query$data || !query$key) return;
    updateWorksite(query$data);
  }, [query$data, query$key, updateWorksite]);
}

export function AssignedWorksite({
  $key,
  client$key,
  presentLabel,
  presentOverrides,
  presentNone,
  presentFallback,
  disabled,
  required,
}: {
  $key: AssignedWorksiteFragment$key | null | undefined;
  client$key: AssignedWorksite_ClientFragment$key | null | undefined;
  presentLabel: AssignedWorksite_Label_PresentFn;
  presentOverrides: AssignedWorksite_Overrides_PresentFn;
  presentNone: () => ReactNode;
  presentFallback: () => ReactNode;
  disabled: boolean;
  required: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment AssignedWorksiteFragment on ProjectInternalBase {
        assignedWorksite {
          ...AssignedWorksite_Label_BaseFragment
          ...AssignedWorksite_Overrides_BaseFragment
        }
        ...ProjectBaseFields_AssignedWorksiteFragment
        assignedWorksiteInfo {
          ...AssignedWorksite_Overrides_DenormalizableFragment
          ...AssignedWorksite_UseHasWorksiteInfoFragment
        }
      }
    `,
    $key,
  );

  const client$data = useFragment(
    graphql`
      fragment AssignedWorksite_ClientFragment on ClientInternalBase {
        ...AssignedWorksite_Overrides_ClientFragment
      }
    `,
    client$key,
  );

  const { assignedWorksite, assignedWorksiteIsDirty } = useFieldAssignedWorksiteRead($data);
  const isNewWorksite = assignedWorksite?.id === 'new';
  const hasWorksiteInfo = useHasWorksiteInfo($data?.assignedWorksiteInfo);
  const { shouldNotifyForKey } = useOperations(UPDATE_ASSIGNED_WORKSITE_OPERATION_KEY);
  const [updatedAssignedWorksite$keys] = useSharedState(jobSharedStateContext, updatedAssignedWorksite$keysSharedStateKey);

  if (shouldNotifyForKey) return presentFallback();

  if (!assignedWorksite && !hasWorksiteInfo) return presentNone();

  // TODO refactor the following code to be more linear / robust

  let label_base$key: AssignedWorksite_LabelProps['base$key'] = null;
  let overrides_denormalizable$key: AssignedWorksite_OverridesProps['denormalizable$key'] = null;
  let overrides_base$key: AssignedWorksite_OverridesProps['base$key'] = null;

  // Worksite has been picked
  if (assignedWorksiteIsDirty && assignedWorksite && !isNewWorksite && updatedAssignedWorksite$keys) {
    label_base$key = updatedAssignedWorksite$keys.baseLabel$key;
    overrides_denormalizable$key = updatedAssignedWorksite$keys.denormalizableOverrides$key;
    overrides_base$key = updatedAssignedWorksite$keys.baseOverrides$key;
  }

  // New worksite has been picked (a no-op, but left there as a historical cue)
  if (assignedWorksiteIsDirty && assignedWorksite && isNewWorksite) {
    label_base$key = null;
    overrides_denormalizable$key = null;
    overrides_base$key = null;
  }

  // Load worksite from server
  if (!assignedWorksiteIsDirty && assignedWorksite && $data?.assignedWorksite) {
    label_base$key = $data.assignedWorksite;
    overrides_denormalizable$key = $data.assignedWorksiteInfo;
    overrides_base$key = $data.assignedWorksite;
  }

  // Load manually created worksite from server
  if (!assignedWorksiteIsDirty && !assignedWorksite && hasWorksiteInfo) {
    label_base$key = $data?.assignedWorksite;
    overrides_denormalizable$key = $data?.assignedWorksiteInfo;
    overrides_base$key = $data?.assignedWorksite;
  }

  return (
    <>
      <AssignedWorksite_Label base$key={label_base$key} present={presentLabel} />
      <AssignedWorksite_Overrides
        denormalizable$key={overrides_denormalizable$key}
        base$key={overrides_base$key}
        client$key={client$data}
        present={presentOverrides}
        disabled={disabled}
        required={required}
      />
    </>
  );
}

export type AssignedWorksite_Label_PresentFn = (renders: {
  renderNumber: () => ReactNode;
  renderInactiveLabel?: (props?: Partial<TypographyProps>) => ReactNode;
}) => ReactNode;
export interface AssignedWorksite_LabelProps {
  base$key: AssignedWorksite_Label_BaseFragment$key | null | undefined;
  present: AssignedWorksite_Label_PresentFn;
}
export function AssignedWorksite_Label({ base$key, present }: AssignedWorksite_LabelProps) {
  const { t } = useTranslation('worksite');
  const theme = useTheme();

  const base$data = useFragment(
    graphql`
      fragment AssignedWorksite_Label_BaseFragment on Worksite {
        code
        deletedAt
      }
    `,
    base$key,
  );

  return present({
    renderNumber: () => (base$data?.code ? <Box data-testid='worksiteNumber'>#&nbsp;{base$data.code}</Box> : t('new')),
    renderInactiveLabel: base$data?.deletedAt
      ? ({ sx, ...rest } = {}) => (
          <Typography variant='overline' sx={[{ color: theme.palette.error.main }, sx ?? null].flat()} {...rest}>
            {t('inactive', { ns: 'common' })}
          </Typography>
        )
      : undefined,
  });
}

export type AssignedWorksite_Overrides_PresentFn = (renders: {
  renderName: () => ReactNode;
  renderLocation: () => ReactNode;
  renderRequirements: (
    $key: ProjectBaseFields_WorksiteRequirementsSuggestionsFragment$key | null | undefined,
    saleKind: ServiceCallKind,
  ) => ReactNode;
  renderRequirementsNoSuggestions: () => ReactNode;
  renderContact: () => ReactNode;
  renderPhoneNumber: () => ReactNode;
}) => ReactNode;
export interface AssignedWorksite_OverridesProps {
  base$key: AssignedWorksite_Overrides_BaseFragment$key | null | undefined;
  denormalizable$key: AssignedWorksite_Overrides_DenormalizableFragment$key | null | undefined;
  client$key: AssignedWorksite_Overrides_ClientFragment$key | null | undefined;
  present: AssignedWorksite_Overrides_PresentFn;
  disabled: boolean;
  required: boolean;
}
export function AssignedWorksite_Overrides({
  base$key,
  denormalizable$key,
  client$key,
  present,
  disabled,
  required,
}: AssignedWorksite_OverridesProps) {
  const base$data = useFragment(
    graphql`
      fragment AssignedWorksite_Overrides_BaseFragment on Worksite {
        requirements {
          ...ProjectBaseFields_RequirementsFragment
        }
      }
    `,
    base$key,
  );

  const denormalizable$data = useFragment(
    graphql`
      fragment AssignedWorksite_Overrides_DenormalizableFragment on IOverridableWorksite {
        ...ProjectBaseFields_WorksiteOverrides_NameFragment
        ...ProjectBaseFields_WorksiteOverrides_LocationFragment
        requirements {
          ...ProjectBaseFields_RequirementsFragment
        }
        ...ProjectBaseFields_WorksiteOverrides_ContactFragment
        ...ProjectBaseFields_WorksiteOverrides_PhoneNumberFragment
      }
    `,
    denormalizable$key,
  );

  const client$data = useFragment(
    graphql`
      fragment AssignedWorksite_Overrides_ClientFragment on ClientInternalBase {
        ...ProjectBaseFields_WorksiteOverrides_ClientLocationFragment
      }
    `,
    client$key,
  );

  const { renderWorksiteOverridesName } = useFieldWorksiteOverridesName(denormalizable$data, disabled, required);
  const { renderWorksiteOverridesLocation } = useFieldWorksiteOverridesLocation(denormalizable$data, client$data, disabled, required);
  const { renderWorksiteOverridesRequirements, renderWorksiteOverridesRequirementsNoSuggestions } = useFieldWorksiteOverridesRequirements(
    denormalizable$data?.requirements,
    base$data?.requirements,
    disabled,
  );
  const { renderWorksiteOverridesContact } = useFieldWorksiteOverridesContact(denormalizable$data, disabled);
  const { renderWorksiteOverridesPhoneNumber } = useFieldWorksiteOverridesPhoneNumber(denormalizable$data, disabled);

  return present({
    renderName: () => renderWorksiteOverridesName(),
    renderLocation: () => renderWorksiteOverridesLocation(),
    renderRequirements: ($key, saleKind) => renderWorksiteOverridesRequirements($key, saleKind),
    renderRequirementsNoSuggestions: () => renderWorksiteOverridesRequirementsNoSuggestions(),
    renderContact: () => renderWorksiteOverridesContact(),
    renderPhoneNumber: () => renderWorksiteOverridesPhoneNumber(),
  });
}

export function useHasWorksiteInfo($key: AssignedWorksite_UseHasWorksiteInfoFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment AssignedWorksite_UseHasWorksiteInfoFragment on WorksiteInfoInternal {
        name
        location {
          address
        }
        requirementIds
        contact
        phoneNumber
      }
    `,
    $key,
  );

  return (
    !!$data?.name || !!$data?.location?.address || ($data?.requirementIds ?? []).length > 0 || !!$data?.contact || !!$data?.phoneNumber
  );
}
