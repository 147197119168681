import { useFieldAssignedClientRead, useFieldClientOverridesRequirementsRead } from './fields/ClientBaseFields';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { SuggestionPromptInput } from '../common/components/__generated__/SuggestionsFakeQuery.graphql';
import { useSuggestionsFragment$key } from './__generated__/useSuggestionsFragment.graphql';
import {
  useFieldDispatchBranchRead,
  useFieldIsSecondServingRead,
  useFieldNatureOfWorkRead,
  useFieldNatureOfWorkSubCategoryRead,
  useFieldSalesBranchRead,
  useFieldWorkDescriptionRead,
} from './fields/SaleProjectFields';
import {
  useFieldAssignedWorksiteRead,
  useFieldDurationHoursPerDayRead,
  useFieldDurationLengthInDaysRead,
  useFieldWorksiteOverridesRequirementsRead,
} from './fields/ProjectBaseFields';
import { useFieldInTheCareOfRead, useFieldRepresentativeRead } from './fields/SaleClientFields';
import { ServiceCallKind } from '../__enums__/ServiceCallKind';
import { useFieldAccessoryLinesCollectionRead } from './accessoryLines/AccessoryLinesFields';
import { useMemo } from 'react';
import { useCraneSelectorFavorite } from './JobEquipment.CraneSelector.Favorite';
import { useJobKindRequiresEquipment } from './fields/SaleEquipmentFields';

export function useSuggestions($key: useSuggestionsFragment$key | null | undefined, saleKind: ServiceCallKind): SuggestionPromptInput {
  const $data = useFragment(
    graphql`
      fragment useSuggestionsFragment on ISale @argumentDefinitions(skipAccessories: { type: "Boolean!" }) {
        clientBase {
          ...ClientBaseFields_useFieldAssignedClientFragment
          assignedClient {
            requirements {
              ...ClientBaseFields_RequirementsFragment
            }
          }
          assignedClientInfo {
            requirements {
              ...ClientBaseFields_RequirementsFragment
            }
          }
        }
        client {
          ...SaleClientFields_InTheCareOfFragment
          ...SaleClientFields_RepresentativeFragment
        }
        projectBase {
          ...ProjectBaseFields_DurationHoursPerDayFragment
          ...ProjectBaseFields_DurationLengthInDaysFragment
          ...ProjectBaseFields_AssignedWorksiteFragment
          assignedWorksite {
            requirements {
              ...ProjectBaseFields_RequirementsFragment
            }
          }
          assignedWorksiteInfo {
            requirements {
              ...ProjectBaseFields_RequirementsFragment
            }
          }
        }
        project {
          ...SaleProjectFields_DispatchBranchFragment
          ...SaleProjectFields_IsSecondServingFragment
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_NatureOfWorkSubCategoryFragment
          ...SaleProjectFields_SalesBranchFragment
          ...SaleProjectFields_WorkDescriptionFragment
        }
        equipmentBase {
          craneSelector {
            ...JobEquipment_useCraneSelectorFavoriteFragment
          }
        }
        costsBase {
          ...AccessoryLinesFields_AccessoryLineCollectionFragment @arguments(skipAccessories: $skipAccessories)
        }
      }
    `,
    $key,
  );

  const { accessoryLines } = useFieldAccessoryLinesCollectionRead($data?.costsBase);
  const accessoryTypeCodes = useMemo(
    () => accessoryLines.flatMap((a) => (a.accessoryType?.code ? [a.accessoryType.code.toString()] : [])),
    [accessoryLines],
  );
  const { assignedClient } = useFieldAssignedClientRead($data?.clientBase);
  const { clientOverridesRequirements } = useFieldClientOverridesRequirementsRead(
    $data?.clientBase.assignedClientInfo.requirements,
    $data?.clientBase.assignedClient?.requirements,
  );
  const { inTheCareOf } = useFieldInTheCareOfRead($data?.client);
  const { dispatchBranch } = useFieldDispatchBranchRead($data?.project);
  const { durationHoursPerDay } = useFieldDurationHoursPerDayRead($data?.projectBase);
  const { isSecondServing } = useFieldIsSecondServingRead($data?.project);
  const { durationLengthInDays } = useFieldDurationLengthInDaysRead($data?.projectBase);
  const { natureOfWork } = useFieldNatureOfWorkRead($data?.project);
  const { natureOfWorkSubCategory } = useFieldNatureOfWorkSubCategoryRead($data?.project);
  const { representative } = useFieldRepresentativeRead($data?.client);
  const { salesBranch } = useFieldSalesBranchRead($data?.project);
  const { workDescription } = useFieldWorkDescriptionRead($data?.project);
  const { assignedWorksite } = useFieldAssignedWorksiteRead($data?.projectBase);
  const { worksiteOverridesRequirements } = useFieldWorksiteOverridesRequirementsRead(
    $data?.projectBase.assignedWorksiteInfo.requirements,
    $data?.projectBase.assignedWorksite?.requirements,
  );
  const isEquipmentSectionRequired = useJobKindRequiresEquipment(saleKind);
  const { favorite } = useCraneSelectorFavorite($data?.equipmentBase.craneSelector, isEquipmentSectionRequired);

  return useMemo(
    () => ({
      accessoryTypes: accessoryTypeCodes,
      capacity: favorite?.capacity.capacity ?? null,
      clientId: assignedClient?.id ?? null,
      clientRequirements: clientOverridesRequirements?.map((r) => r.id) ?? [],
      dispatchBranchId: dispatchBranch?.id ?? null,
      equipmentKindCode: favorite?.equipmentKind.code.toString() ?? null,
      hoursPerDay: durationHoursPerDay,
      inTheCareOf,
      isSecondServing,
      lengthInDays: durationLengthInDays,
      natureOfWorkCode: natureOfWork?.code.toString() ?? null,
      natureOfWorkSubCategory: natureOfWorkSubCategory,
      representativeId: representative?.id ?? null,
      salesBranchId: salesBranch?.id ?? null,
      serviceCallKind: saleKind,
      vehicleIds: favorite?.vehicleId?.label ? [favorite?.vehicleId.label] : [],
      workDescription,
      worksiteId: assignedWorksite?.id == null || assignedWorksite.id === 'new' ? null : assignedWorksite.id,
      worksiteRequirements: worksiteOverridesRequirements?.map((r) => r.id) ?? [],
    }),
    [
      accessoryTypeCodes,
      assignedClient?.id,
      assignedWorksite?.id,
      clientOverridesRequirements,
      dispatchBranch?.id,
      durationHoursPerDay,
      durationLengthInDays,
      favorite?.capacity.capacity,
      favorite?.equipmentKind.code,
      favorite?.vehicleId?.label,
      inTheCareOf,
      isSecondServing,
      natureOfWork?.code,
      natureOfWorkSubCategory,
      representative?.id,
      saleKind,
      salesBranch?.id,
      workDescription,
      worksiteOverridesRequirements,
    ],
  );
}
