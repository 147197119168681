import { useFieldCraneConfigurationCollectionFavorite, useFieldCraneSelectorModeRead } from './fields/SaleCraneSelectorFields';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useEffect, useMemo } from 'react';
import { useFieldAdditionalCranesAutomaticCollection } from './AdditionalCranesFields';
import { JobEquipment_useCraneSelectorAutomaticFavoriteFragment$key } from './__generated__/JobEquipment_useCraneSelectorAutomaticFavoriteFragment.graphql';
import { useEffectEvent } from '../common/utils/effectUtils';
import { useOperations, useOperationsError } from '../AppSharedState';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import * as Sentry from '@sentry/react';
import { JobEquipment_useSyncAdditionalCranesAutomaticQuery } from './__generated__/JobEquipment_useSyncAdditionalCranesAutomaticQuery.graphql';
import { JobEquipment_useSyncAdditionalCranesAutomaticFragment$key } from './__generated__/JobEquipment_useSyncAdditionalCranesAutomaticFragment.graphql';

const NO_VALUE = Symbol();
const hasValue = <T,>(v: T): v is Exclude<T, typeof NO_VALUE> => v !== NO_VALUE;

export function useCraneSelectorAutomaticFavorite(
  $key: JobEquipment_useCraneSelectorAutomaticFavoriteFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorAutomaticFavoriteFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
      }
    `,
    $key,
  );

  const { craneConfigurationCollectionFavorite, craneConfigurationCollectionFavoriteIsDirty } =
    useFieldCraneConfigurationCollectionFavorite($data, required);

  const favorite$data = useFragment(
    graphql`
      fragment JobEquipment_AutomaticFavoriteFragment on AutomaticConfigurationInfo {
        capacity @required(action: THROW) {
          capacity
          label
        }
        equipmentKind @required(action: THROW) {
          id
          code
          label
        }
        vehicleId @required(action: THROW) {
          key
          label
        }
        configurationKind @required(action: THROW) {
          id
          code
          label
        }
        boomConfiguration @required(action: THROW) {
          id
          label
          preparationHours
        }
        boomLength
        jibLength
        counterweight
        offsetAngle
        radius
        maxWeight
      }
    `,
    craneConfigurationCollectionFavorite?.matchingCostLineAutomaticRulesFavorite$key,
  );

  const { additionalCranesAutomatic, additionalCranesAutomaticAreDirty } = useFieldAdditionalCranesAutomaticCollection(
    $data?.automaticConfiguration,
  );

  // For some obscure reason, useFragment() seems to sometimes return a new deep copy of favorite$data despite being
  // given the exact same key as input. As a result, favorite$data cannot be used as a dependency of useMemo(),
  // otherwise useMemo() ends up creating new objects much more often than it's supposed to, which are then propagated
  // "upstream", incorrectly triggering API requests, which overwrite form fields which shouldn't be overwritten. As a
  // workaround, each primitive value is extracted into its own variable, and useMemo() is made to depend on all of
  // them, eliminating these spurious recomputations and API requests.
  // See:
  // - https://vooban.atlassian.net/browse/GG-10138
  // - https://vooban.atlassian.net/browse/GG-10166

  const favorite = <T,>(getter: (f: NonNullable<typeof favorite$data>) => T): T | typeof NO_VALUE =>
    favorite$data != null ? getter(favorite$data) : NO_VALUE;

  const favoriteCapacityCapacity = favorite((f) => f.capacity.capacity);
  const favoriteCapacityLabel = favorite((f) => f.capacity.label);
  const favoriteEquipmentKindId = favorite((f) => f.equipmentKind.id);
  const favoriteEquipmentKindLabel = favorite((f) => f.equipmentKind.label);
  const favoriteEquipmentKindCode = favorite((f) => f.equipmentKind.code);
  const favoriteVehicleIdKey = favorite((f) => f.vehicleId.key);
  const favoriteVehicleIdLabel = favorite((f) => f.vehicleId.label);
  const favoriteConfigurationKindId = favorite((f) => f.configurationKind.id);
  const favoriteConfigurationKindCode = favorite((f) => f.configurationKind.code);
  const favoriteConfigurationKindLabel = favorite((f) => f.configurationKind.label);
  const favoriteBoomConfigurationId = favorite((f) => f.boomConfiguration.id);
  const favoritePreparationHours = favorite((f) => f.boomConfiguration.preparationHours);
  const favoriteBoomConfigurationLabel = favorite((f) => f.boomConfiguration.label);
  const favoriteBoomLength = favorite((f) => f.boomLength);
  const favoriteJibLength = favorite((f) => f.jibLength);
  const favoriteCounterweight = favorite((f) => f.counterweight);
  const favoriteOffsetAngle = favorite((f) => f.offsetAngle);
  const favoriteRadius = favorite((f) => f.radius);
  const favoriteMaxWeight = favorite((f) => f.maxWeight);

  const automaticFavorite = useMemo(
    () =>
      hasValue(favoriteCapacityCapacity) &&
      hasValue(favoriteCapacityLabel) &&
      hasValue(favoriteEquipmentKindId) &&
      hasValue(favoriteEquipmentKindLabel) &&
      hasValue(favoriteEquipmentKindCode) &&
      hasValue(favoriteVehicleIdKey) &&
      hasValue(favoriteVehicleIdLabel) &&
      hasValue(favoriteConfigurationKindId) &&
      hasValue(favoriteConfigurationKindCode) &&
      hasValue(favoriteConfigurationKindLabel) &&
      hasValue(favoriteBoomConfigurationId) &&
      hasValue(favoritePreparationHours) &&
      hasValue(favoriteBoomConfigurationLabel) &&
      hasValue(favoriteBoomLength) &&
      hasValue(favoriteJibLength) &&
      hasValue(favoriteCounterweight) &&
      hasValue(favoriteOffsetAngle) &&
      hasValue(favoriteRadius) &&
      hasValue(favoriteMaxWeight)
        ? {
            capacity: {
              capacity: favoriteCapacityCapacity,
              label: favoriteCapacityLabel,
            },
            equipmentKind: {
              id: favoriteEquipmentKindId,
              label: favoriteEquipmentKindLabel,
              code: favoriteEquipmentKindCode,
            },
            vehicleId: {
              key: favoriteVehicleIdKey,
              label: favoriteVehicleIdLabel,
            },
            configurationKind: {
              id: favoriteConfigurationKindId,
              code: favoriteConfigurationKindCode,
              label: favoriteConfigurationKindLabel,
            },
            boomConfiguration: {
              id: favoriteBoomConfigurationId,
              label: favoriteBoomConfigurationLabel,
              preparationHours: favoritePreparationHours,
            },
            boomLength: favoriteBoomLength,
            jibLength: favoriteJibLength,
            counterweight: favoriteCounterweight,
            offsetAngle: favoriteOffsetAngle,
            radius: favoriteRadius,
            maxWeight: favoriteMaxWeight,
            additionalCranes: additionalCranesAutomatic.filter((v) => !v.$removed),
          }
        : null,
    [
      additionalCranesAutomatic,
      favoriteBoomConfigurationId,
      favoriteBoomConfigurationLabel,
      favoriteBoomLength,
      favoriteCapacityCapacity,
      favoriteCapacityLabel,
      favoriteConfigurationKindCode,
      favoriteConfigurationKindId,
      favoriteConfigurationKindLabel,
      favoriteCounterweight,
      favoriteEquipmentKindCode,
      favoriteEquipmentKindId,
      favoriteEquipmentKindLabel,
      favoriteJibLength,
      favoriteMaxWeight,
      favoriteOffsetAngle,
      favoritePreparationHours,
      favoriteRadius,
      favoriteVehicleIdKey,
      favoriteVehicleIdLabel,
    ],
  );

  const automaticFavoriteIsDirty = useMemo(() => {
    return craneConfigurationCollectionFavoriteIsDirty || additionalCranesAutomaticAreDirty;
  }, [additionalCranesAutomaticAreDirty, craneConfigurationCollectionFavoriteIsDirty]);
  return { automaticFavorite, automaticFavoriteIsDirty, additionalCranesAutomaticAreDirty };
}

export const SYNC_ADDITIONAL_CRANES_AUTOMATIC_OP_KEY = 'useSyncAdditionalCranesAutomatic';
export function useSyncAdditionalCranesAutomatic(
  $key: JobEquipment_useSyncAdditionalCranesAutomaticFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useSyncAdditionalCranesAutomaticFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneConfigurationCollection_FavoriteFragment
        ...SaleCraneSelectorFields_CraneSelectorModeFragment
        automaticConfiguration {
          ...AdditionalCranesFields_AdditionalCranesAutomaticCollectionFragment
        }
      }
    `,
    $key,
  );

  const { craneSelectorMode } = useFieldCraneSelectorModeRead($data);

  const { craneConfigurationCollectionFavorite, craneConfigurationCollectionFavoriteIsDirty } =
    useFieldCraneConfigurationCollectionFavorite($data, required);

  const favorite$data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorAutomaticFavorite_BoomConfigurationFragment on AutomaticConfigurationInfo {
        boomConfigurationId
      }
    `,
    craneConfigurationCollectionFavorite?.matchingConfigurationBoomConfiguration$key,
  );

  const { appendAdditionalCraneAutomatic, clearAdditionalCranesAutomatic } = useFieldAdditionalCranesAutomaticCollection(
    $data?.automaticConfiguration,
  );

  const environment = useRelayEnvironment();

  const { startOperation, endOperation } = useOperations(SYNC_ADDITIONAL_CRANES_AUTOMATIC_OP_KEY);
  const { resetError, setError } = useOperationsError(SYNC_ADDITIONAL_CRANES_AUTOMATIC_OP_KEY);
  const [_, setSubscription] = useCancellableSubscription();

  const refreshAdditionalCranes = useEffectEvent((id: string) => {
    startOperation();
    resetError();

    setSubscription(
      fetchQuery<JobEquipment_useSyncAdditionalCranesAutomaticQuery>(
        environment,
        graphql`
          query JobEquipment_useSyncAdditionalCranesAutomaticQuery($id: ID!) {
            node(id: $id) @required(action: THROW) {
              __typename
              ... on BoomConfigurationSnapshot {
                additionals {
                  additionalBoomConfiguration @required(action: THROW) {
                    capacity
                    equipmentKind @required(action: THROW) {
                      id
                      code
                      label
                    }
                    configurationKind @required(action: THROW) {
                      id
                      code
                      label
                    }
                    id
                    label
                    preparationHours
                  }
                }
              }
            }
          }
        `,
        { id: id },
      ).subscribe({
        error: (error: Error) => {
          Sentry.captureException(error);
          endOperation();
          setError(error);
        },
        next: (value) => {
          if (value.node.__typename !== 'BoomConfigurationSnapshot') {
            endOperation();
            throw new Error(`Invalid node type, expected BoomConfigurationSnapshot but got ${value.node.__typename}`);
          }

          const edges = value.node.additionals;
          const nodes = edges.filter((x): x is NonNullable<(typeof edges)[number]> => !!x);
          for (const { additionalBoomConfiguration } of nodes) {
            appendAdditionalCraneAutomatic({
              id: 'new',
              capacity: {
                capacity: additionalBoomConfiguration.capacity,
                label: `${additionalBoomConfiguration.capacity}`,
              },
              equipmentKind: additionalBoomConfiguration.equipmentKind,
              configurationKind: additionalBoomConfiguration.configurationKind,
              boomConfiguration: {
                id: additionalBoomConfiguration.id,
                label: additionalBoomConfiguration.label,
                preparationHours: additionalBoomConfiguration.preparationHours,
              },
            });
          }
          endOperation();
        },
        unsubscribe: () => {
          endOperation();
          resetError();
        },
      }),
    );
  });

  const clearAdditionalCranes = useEffectEvent(() => clearAdditionalCranesAutomatic());

  useEffect(() => {
    if (!craneConfigurationCollectionFavoriteIsDirty || craneSelectorMode !== 'lifts') {
      return;
    }

    clearAdditionalCranes();
    if (favorite$data?.boomConfigurationId) {
      refreshAdditionalCranes(favorite$data.boomConfigurationId);
    }
  }, [
    clearAdditionalCranes,
    craneConfigurationCollectionFavoriteIsDirty,
    craneSelectorMode,
    favorite$data?.boomConfigurationId,
    refreshAdditionalCranes,
  ]);
}
