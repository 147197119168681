import graphql from 'babel-plugin-relay/macro';
import { DetailsLayout, SidebarContentProps } from '../../layout/Layouts';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { Suspense, useCallback } from 'react';
import { ElementNotFoundErrorBoundary } from '../../layout/ElementNotFoundErrorBoundary';
import { DataID, useFragment, useLazyLoadQuery } from 'react-relay';
import { FormSectionSkeleton } from '../../layout/components/loaders/FormSectionSkeleton';
import { useAmbientTranslation } from '../../common/hooks/useAmbientTranslation';
import { ElementNotFoundError } from '../../common/exceptions/ElementNotFoundError';
import { RequireAdmin, UnauthorizedFallback } from '../../auth/Authorization';
import { ConfigurationPageQuery$data } from '../__generated__/ConfigurationPageQuery.graphql';
import { NavigationMenu } from '../../layout/SidebarDrawer';
import { ErrorBanner } from '../../common/components/ErrorBanner';
import { FormSectionContent, FormSectionHeader, FormSectionHeader_Title } from '../../layout/FormLayout';
import { FormProvider } from '../../common/utils/forms';
import {
  addendumTemplateFormContext,
  useAddendumTemplateKind,
  useAddendumTemplateSaleKinds,
  useAddendumTemplateTemplateEn,
  useAddendumTemplateTemplateFr,
  useAddendumTemplateTitleEn,
  useAddendumTemplateTitleFr,
  useFieldAddendumTemplateIsAutomatic,
  useFieldNatureOfWorkCodes,
  useFieldNatureOfWorkSubCategories,
  useFieldQuestionKinds,
} from './AddendumTemplateFields';
import { AddendumTemplateDetailsPageFragment$key } from './__generated__/AddendumTemplateDetailsPageFragment.graphql';
import { AddendumTemplateDetailsPageQuery } from './__generated__/AddendumTemplateDetailsPageQuery.graphql';
import { AddendumTemplateDeleteButton } from './AddendumTemplateDeleteButton';
import { AddendumTemplateSaveButton } from './AddendumTemplateSaveButton';
import { Box } from '@mui/material';

const flagName = 'app_navigation_configuration';

export function AddendumTemplateDetailsPage() {
  const { t } = useAmbientTranslation();
  const $data = useOutletContext<ConfigurationPageQuery$data>();

  return (
    <ElementNotFoundErrorBoundary heading={t('addenda.edit')} flagName={flagName} sidebar$key={$data} detailsLayout$key={$data}>
      <Suspense fallback={<AddendumTemplateDetailsSkeleton $data={$data} />}>
        <AddendumTemplateDetails />
      </Suspense>
    </ElementNotFoundErrorBoundary>
  );
}

function AddendumTemplateDetailsSkeleton({ $data }: { $data: ConfigurationPageQuery$data }) {
  const { t } = useAmbientTranslation();
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);

  return (
    <DetailsLayout heading={t('addenda.edit')} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
      <FormSectionSkeleton />
    </DetailsLayout>
  );
}

function AddendumTemplateDetails() {
  const params = useParams<{ id: string }>();
  const id = params.id ?? 'new';

  const addendum = useLazyLoadQuery<AddendumTemplateDetailsPageQuery>(
    graphql`
      query AddendumTemplateDetailsPageQuery($id: ID!, $isNew: Boolean!) {
        node(id: $id) @skip(if: $isNew) {
          ... on AddendumTemplate {
            ...AddendumTemplateDetailsPageFragment
          }
        }
      }
    `,
    { id, isNew: id === 'new' },
    { fetchPolicy: 'store-and-network' },
  );

  if (id !== 'new' && addendum.node == null) {
    throw new ElementNotFoundError('AddendumTemplate', id, '/configuration/addenda');
  }

  return (
    <FormProvider key={id} context={addendumTemplateFormContext}>
      <AddendumTemplateForm $key={addendum.node} />;
    </FormProvider>
  );
}

function AddendumTemplateForm({ $key }: { $key: AddendumTemplateDetailsPageFragment$key | null | undefined }) {
  const { t } = useAmbientTranslation();
  const params = useParams<{ id: string }>();
  const $data = useOutletContext<ConfigurationPageQuery$data>();
  const navigate = useNavigate();

  const addendumTemplate$data = useFragment(
    graphql`
      fragment AddendumTemplateDetailsPageFragment on AddendumTemplate {
        id
        ...AddendumTemplateFields_TitleFrFragment
        ...AddendumTemplateFields_TitleEnFragment
        ...AddendumTemplateFieldsKindFragment
        ...AddendumTemplateFields_TemplateFrFragment
        ...AddendumTemplateFields_TemplateEnFragment
        ...AddendumTemplateFields_useFieldAddendumTemplateIsAutomaticFragment
        ...AddendumTemplateFieldsSaleKindsFragment
        ...AddendumTemplateFieldsNatureOfWorkCodesFragment
        ...AddendumTemplateFieldsNatureOfWorkSubcategoryFragment
        ...AddendumTemplateFieldsQuestionKindsFragment
      }
    `,
    $key,
  );

  const heading = params.id === 'new' ? t('addenda.new') : t('addenda.edit');
  const sidebar = useCallback((props: SidebarContentProps) => <NavigationMenu {...props} $key={$data} />, [$data]);
  const { renderAddendumTemplateTitleFr } = useAddendumTemplateTitleFr(addendumTemplate$data);
  const { renderAddendumTemplateTitleEn } = useAddendumTemplateTitleEn(addendumTemplate$data);
  const { renderAddendumTemplateKind } = useAddendumTemplateKind(addendumTemplate$data);
  const { renderIsAutomatic, isAutomatic } = useFieldAddendumTemplateIsAutomatic(addendumTemplate$data);
  const { renderAddendumTemplateTemplateFr } = useAddendumTemplateTemplateFr(addendumTemplate$data);
  const { renderAddendumTemplateTemplateEn } = useAddendumTemplateTemplateEn(addendumTemplate$data);
  const { renderAddendumTemplateSaleKinds } = useAddendumTemplateSaleKinds(addendumTemplate$data);
  const { renderNatureOfWorkCodes } = useFieldNatureOfWorkCodes(addendumTemplate$data);
  const { renderNatureOfWorkSubCategories } = useFieldNatureOfWorkSubCategories(addendumTemplate$data);
  const { renderQuestionKinds } = useFieldQuestionKinds(addendumTemplate$data);

  return (
    <RequireAdmin
      $key={$data}
      fallback={
        <DetailsLayout heading={heading} flagName={flagName} sidebarProvider={sidebar} $key={$data}>
          <UnauthorizedFallback onButtonClick={() => navigate('/service-calls')} />
        </DetailsLayout>
      }>
      <DetailsLayout
        heading={heading}
        flagName={flagName}
        sidebarProvider={sidebar}
        $key={$data}
        actions={<AddendumTemplateCommands id={addendumTemplate$data?.id ?? null} />}>
        <ErrorBanner />

        <FormSectionHeader label={<FormSectionHeader_Title label={t('addenda.information')} />} />
        <FormSectionContent
          sx={{
            display: 'grid',
            gap: '0.5rem',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}>
          {renderAddendumTemplateTitleFr()}
          {renderAddendumTemplateTitleEn()}
          {renderAddendumTemplateKind()}
          <Box sx={{ gridColumnEnd: 'span 3' }}>{renderAddendumTemplateTemplateFr()}</Box>
          <Box sx={{ gridColumnEnd: 'span 3' }}>{renderAddendumTemplateTemplateEn()}</Box>
          {renderIsAutomatic()}
        </FormSectionContent>
        {isAutomatic && (
          <>
            <FormSectionHeader label={<FormSectionHeader_Title label={t('addenda.conditions')} />} />
            <FormSectionContent>
              {renderAddendumTemplateSaleKinds()}
              {renderNatureOfWorkCodes()}
              {renderNatureOfWorkSubCategories()}
              {renderQuestionKinds()}
            </FormSectionContent>
          </>
        )}
      </DetailsLayout>
    </RequireAdmin>
  );
}

function AddendumTemplateCommands({ id }: { id: DataID | null }) {
  return (
    <>
      {id && <AddendumTemplateDeleteButton id={id} />}
      <AddendumTemplateSaveButton id={id} />
    </>
  );
}
