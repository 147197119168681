import { useFieldCraneSelectorModeRead } from './fields/SaleCraneSelectorFields';
import { useCraneSelectorManualFavorite } from './JobEquipment.CraneSelector.Manual';
import { useCraneSelectorAutomaticFavorite } from './JobEquipment.CraneSelector.Automatic';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useMemo } from 'react';
import { JobEquipment_useCraneSelectorFavoriteFragment$key } from './__generated__/JobEquipment_useCraneSelectorFavoriteFragment.graphql';
import { JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$key } from './__generated__/JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment.graphql';

export function useCraneSelectorFavorite($key: JobEquipment_useCraneSelectorFavoriteFragment$key | null | undefined, required: boolean) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorFavoriteFragment on CraneSelectorInternal {
        ...SaleCraneSelectorFields_CraneSelectorModeFragment
        ...JobEquipment_useCraneSelectorAutomaticFavoriteFragment
        manualConfiguration {
          ...JobEquipment_useCraneSelectorManualFavoriteFragment
        }
      }
    `,
    $key,
  );

  const { craneSelectorMode } = useFieldCraneSelectorModeRead($data);

  //ManualEquipment
  const { manualFavorite, manualFavoriteIsDirty, additionalCranesManualAreDirty } = useCraneSelectorManualFavorite(
    $data?.manualConfiguration,
    $data,
    true,
    required,
    false,
  );

  //AutomaticEquipment
  const { automaticFavorite, automaticFavoriteIsDirty, additionalCranesAutomaticAreDirty } = useCraneSelectorAutomaticFavorite(
    $data,
    required,
  );
  return useMemo(
    () =>
      craneSelectorMode === 'lifts'
        ? {
            favorite: automaticFavorite,
            favoriteIsDirty: automaticFavoriteIsDirty,
            favoriteAdditionalCraneAreDirty: additionalCranesAutomaticAreDirty,
          }
        : {
            favorite: manualFavorite,
            favoriteIsDirty: manualFavoriteIsDirty,
            favoriteAdditionalCraneAreDirty: additionalCranesManualAreDirty,
          },
    [
      additionalCranesAutomaticAreDirty,
      additionalCranesManualAreDirty,
      automaticFavorite,
      automaticFavoriteIsDirty,
      craneSelectorMode,
      manualFavorite,
      manualFavoriteIsDirty,
    ],
  );
}

export function useCraneSelectorFavoriteAdditionalBoomConfigurations(
  $key: JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment$key | null | undefined,
  required: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment JobEquipment_useCraneSelectorFavoriteAdditionalBoomConfigurationsFragment on CraneSelectorInternal {
        ...JobEquipment_useCraneSelectorFavoriteFragment
      }
    `,
    $key,
  );

  const { favorite, favoriteAdditionalCraneAreDirty } = useCraneSelectorFavorite($data, required);

  return useMemo(
    () => ({
      favoriteAdditionalBoomConfigurations:
        favorite?.additionalCranes.map(({ deletedAt, boomConfiguration }) => ({
          deletedAt,
          boomConfigurationId: boomConfiguration?.id ?? null,
        })) ?? [],
      favoriteAdditionalBoomConfigurationsAreDirty: favoriteAdditionalCraneAreDirty,
    }),
    [favorite?.additionalCranes, favoriteAdditionalCraneAreDirty],
  );
}
