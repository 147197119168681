import {
  createFieldKey,
  SetValueFn,
  useField,
  useFieldHasErrors,
  useFieldMapper,
  useFieldSetter,
  useFieldValidation,
  useFieldValue,
} from '../../common/utils/forms';
import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { Checkbox, FormControlLabel, Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, ReactNode, useCallback } from 'react';
import { SaleEquipmentFields_PreventSubcontractingFragment$key } from './__generated__/SaleEquipmentFields_PreventSubcontractingFragment.graphql';
import { SaleEquipmentFields_PreventReplacementFragment$key } from './__generated__/SaleEquipmentFields_PreventReplacementFragment.graphql';
import { saleFormContext } from '../SaleFields';
import { QuoteKind } from '../../__enums__/QuoteKind';
import { ServiceCallKind } from '../../__enums__/ServiceCallKind';
import { LengthInput, LengthUnit } from '../../common/components/LengthInput';
import { SaleEquipmentFields_BranchToWorksiteDistanceFragment$key } from './__generated__/SaleEquipmentFields_BranchToWorksiteDistanceFragment.graphql';
import { ApplyInstantCalculatorButton } from '../ApplyInstantCalculatorButton';
import { SxProps } from '@mui/system';
import { SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$key } from './__generated__/SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment.graphql';

export function useJobKindHasEquipment(kind: QuoteKind | ServiceCallKind) {
  return [
    'bare',
    'bareWithOperators',
    'laborRental',
    'laborRentalOnBare',
    'laborRentalOnOperated',
    'operatedHourly',
    'operatedMonthly',
  ].includes(kind);
}

export function useJobKindRequiresEquipment(kind: QuoteKind | ServiceCallKind) {
  return ['bare', 'bareWithOperators', 'operatedHourly', 'operatedMonthly'].includes(kind);
}

const fieldPreventReplacementKey = createFieldKey<boolean>();
export function useFieldPreventReplacement($key: SaleEquipmentFields_PreventReplacementFragment$key | null | undefined, disabled: boolean) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_PreventReplacementFragment on ISaleEquipment {
        preventReplacement
      }
    `,
    $key,
  );

  const [preventReplacement, setPreventReplacement] = useField(
    saleFormContext,
    fieldPreventReplacementKey,
    $data?.preventReplacement ?? false,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldPreventReplacementKey);
  useMapper((v) => ({ equipmentSale: { preventReplacement: v } }), [], 'save');

  const renderPreventReplacement = useCallback(
    () => (
      <SaleEquipmentFields_CheckBoxInput
        value={preventReplacement}
        setValue={setPreventReplacement}
        labelKey='field.equipment.noReplacement'
        disabled={disabled}
      />
    ),
    [disabled, preventReplacement, setPreventReplacement],
  );

  return { preventReplacement, renderPreventReplacement };
}

const fieldPreventSubcontractingKey = createFieldKey<boolean>();
export function useFieldPreventSubcontracting(
  $key: SaleEquipmentFields_PreventSubcontractingFragment$key | null | undefined,
  disabled: boolean,
) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_PreventSubcontractingFragment on ISaleEquipment {
        preventSubcontracting
      }
    `,
    $key,
  );

  const [preventSubcontracting, setPreventSubcontracting] = useField(
    saleFormContext,
    fieldPreventSubcontractingKey,
    $data?.preventSubcontracting ?? false,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldPreventSubcontractingKey);
  useMapper((v) => ({ equipmentSale: { preventSubcontracting: v } }), [], 'save');

  const renderPreventSubcontracting = useCallback(
    () => (
      <SaleEquipmentFields_CheckBoxInput
        value={preventSubcontracting}
        setValue={setPreventSubcontracting}
        labelKey='field.equipment.noSubcontractor'
        disabled={disabled}
      />
    ),
    [disabled, preventSubcontracting, setPreventSubcontracting],
  );

  return { preventSubcontracting, renderPreventSubcontracting };
}

function SaleEquipmentFields_CheckBoxInput({
  value,
  setValue,
  labelKey,
  disabled,
}: {
  value: boolean;
  setValue: SetValueFn<boolean>;
  labelKey: string;
  disabled: boolean;
}) {
  const { t } = useTranslation('craneSelector');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.checked);
    },
    [setValue],
  );

  return (
    <FormControlLabel
      disabled={disabled}
      control={<Checkbox checked={value} onChange={handleChange} />}
      label={t(labelKey)}
      labelPlacement='end'
    />
  );
}

type FieldBranchToWorksiteDistance = string | null;
const fieldBranchToWorksiteDistanceKey = createFieldKey<FieldBranchToWorksiteDistance>();

export function useFieldBranchToWorksiteDistanceRead($key: SaleEquipmentFields_BranchToWorksiteDistanceFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_BranchToWorksiteDistanceFragment on ISaleEquipment {
        branchToWorksiteDistance
      }
    `,
    $key,
  );
  const branchToWorksiteDistance = useFieldValue(
    saleFormContext,
    fieldBranchToWorksiteDistanceKey,
    () => $data?.branchToWorksiteDistance ?? null,
  );

  const useMapper = useFieldMapper(saleFormContext, fieldBranchToWorksiteDistanceKey);
  useMapper(
    (value) => ({
      equipmentSale: {
        branchToWorksiteDistance: value ?? null,
      },
    }),
    [],
    'save',
  );

  return { branchToWorksiteDistance };
}
export function useFieldBranchToWorksiteDistance(
  $key: SaleEquipmentFields_BranchToWorksiteDistanceFragment$key | null | undefined,
  disabled: boolean,
) {
  const { branchToWorksiteDistance, ...rest } = useFieldBranchToWorksiteDistanceRead($key);
  const setBranchToWorksiteDistance = useFieldSetter(saleFormContext, fieldBranchToWorksiteDistanceKey);

  const useValidation = useFieldValidation(saleFormContext, fieldBranchToWorksiteDistanceKey);
  useValidation((v) => !!v, [], 'transferable,submittable:required');

  const renderBranchToWorksiteDistance = useCallback(
    () => <BranchToWorksiteDistanceInput value={branchToWorksiteDistance} setValue={setBranchToWorksiteDistance} disabled={disabled} />,
    [branchToWorksiteDistance, disabled, setBranchToWorksiteDistance],
  );

  return {
    branchToWorksiteDistance,
    renderBranchToWorksiteDistance,
    ...rest,
  };
}

function BranchToWorksiteDistanceInput({
  value,
  setValue,
  disabled,
}: {
  value: FieldBranchToWorksiteDistance;
  setValue: SetValueFn<FieldBranchToWorksiteDistance>;
  disabled: boolean;
}) {
  const { t } = useTranslation('jobs');
  const hasErrors = useFieldHasErrors(saleFormContext, fieldBranchToWorksiteDistanceKey);

  const handleChange = useCallback((v: string | null) => setValue(v), [setValue]);

  return (
    <LengthInput
      enabledUnits={['km'] as const satisfies LengthUnit[]}
      value={value}
      required
      label={t('field.equipment.transport.branchToWorksiteDistance')}
      data-label-key='field.equipment.transport.branchToWorksiteDistance'
      onChange={handleChange}
      disabled={disabled}
      error={hasErrors && !disabled}
    />
  );
}

export type SaleEquipmentFields_InstantCalculator_PresentFn = (render: {
  renderDistanceSaleToWorksiteFields: (sx?: SxProps<Theme>) => ReactNode;
  renderInstantCalculatorButton: () => ReactNode;
}) => ReactNode;
export function SaleEquipmentFields_BranchToWorksiteDistanceCalculator({
  $key,
  presentFn,
  disabled,
}: {
  $key: SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment$key | null | undefined;
  presentFn: SaleEquipmentFields_InstantCalculator_PresentFn;
  disabled: boolean;
}) {
  const $data = useFragment(
    graphql`
      fragment SaleEquipmentFields_BranchToWorksiteDistanceCalculatorFragment on ISale {
        ...ApplyInstantCalculatorButtonFragment
        equipment {
          ...SaleEquipmentFields_BranchToWorksiteDistanceFragment
        }
      }
    `,
    $key,
  );

  const { renderBranchToWorksiteDistance } = useFieldBranchToWorksiteDistance($data?.equipment, disabled);

  return presentFn({
    renderDistanceSaleToWorksiteFields: () => renderBranchToWorksiteDistance(),
    renderInstantCalculatorButton: () => <ApplyInstantCalculatorButton $key={$data} disabled={disabled} />,
  });
}
