import { useFragment } from 'react-relay';
import graphql from 'babel-plugin-relay/macro';
import { useFieldAssignedClientRead } from './fields/ClientBaseFields';
import { useFieldArrivalDateRead, useFieldWorksiteOverridesLocation, useFieldWorksiteOverridesNameRead } from './fields/ProjectBaseFields';
import { useFieldDispatchBranchRead, useFieldNatureOfWorkRead, useFieldNatureOfWorkSubCategoryRead } from './fields/SaleProjectFields';
import {
  useFieldCraneSelectorModeRead,
  useFieldFavoriteConfigurationETag,
  useFieldManualVehicleIdsRead,
} from './fields/SaleCraneSelectorFields';
import { useJobKindRequiresEquipment } from './fields/SaleEquipmentFields';
import { useOutletContext } from 'react-router';
import { SaleDetailsContextValue } from './SaleDetailsContextValue';
import { useCraneSelectorManualFavorite } from './JobEquipment.CraneSelector.Manual';
import { useMissingCostsDependenciesFragment$key } from './__generated__/useMissingCostsDependenciesFragment.graphql';
import { useMemo } from 'react';
import { _never } from '../common/utils/_never';

/**
 * Returns a list of tuples of translation key strings & their namespace which correspond to the missing cost dependencies required to edit the
 * fields of the cost page of a Service Call or Quote. Returns null if all required dependencies are filled.
 *
 * @param $key
 */
export function useMissingCostsDependencies(
  $key: useMissingCostsDependenciesFragment$key | null | undefined,
): (readonly [string, { readonly ns: string }])[] | null {
  const { kind } = useOutletContext<SaleDetailsContextValue>();

  const $data = useFragment(
    graphql`
      fragment useMissingCostsDependenciesFragment on ISale {
        clientBase {
          ...ClientBaseFields_useFieldAssignedClientFragment
          ...ProjectBaseFields_WorksiteOverrides_ClientLocationFragment
        }
        projectBase {
          ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: false)
          assignedWorksiteInfo {
            ...ProjectBaseFields_WorksiteOverrides_NameFragment
            ...ProjectBaseFields_WorksiteOverrides_LocationFragment
          }
        }
        project {
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_NatureOfWorkSubCategoryFragment
          ...SaleProjectFields_DispatchBranchFragment
        }
        equipmentBase {
          craneSelector {
            ...SaleCraneSelectorFields_CraneSelectorModeFragment
            ...SaleCraneSelectorFields_FavoriteConfigurationFragment
            manualConfiguration {
              ...JobEquipment_useCraneSelectorManualFavoriteFragment
              userInput {
                ...SaleCraneSelectorFields_VehicleIdsFragment
              }
            }
          }
        }
      }
    `,
    $key,
  );

  const { assignedClient } = useFieldAssignedClientRead($data?.clientBase);
  const { worksiteOverridesName } = useFieldWorksiteOverridesNameRead($data?.projectBase.assignedWorksiteInfo);
  const { arrivalDate } = useFieldArrivalDateRead($data?.projectBase);
  const { worksiteOverridesLocation } = useFieldWorksiteOverridesLocation(
    $data?.projectBase.assignedWorksiteInfo,
    $data?.clientBase,
    true,
    true,
  );
  const { natureOfWork } = useFieldNatureOfWorkRead($data?.project);
  const { natureOfWorkSubCategory } = useFieldNatureOfWorkSubCategoryRead($data?.project);
  const { craneSelectorMode } = useFieldCraneSelectorModeRead($data?.equipmentBase.craneSelector);
  const isEquipmentSectionRequired = useJobKindRequiresEquipment(kind);
  const { manualFavorite } = useCraneSelectorManualFavorite(
    $data?.equipmentBase.craneSelector.manualConfiguration,
    $data?.equipmentBase.craneSelector,
    true,
    isEquipmentSectionRequired,
    false,
  );
  const { favoriteConfigurationETag } = useFieldFavoriteConfigurationETag($data?.equipmentBase.craneSelector);
  const { dispatchBranch } = useFieldDispatchBranchRead($data?.project);
  const { vehicleIds } = useFieldManualVehicleIdsRead($data?.equipmentBase.craneSelector.manualConfiguration.userInput);
  const kindRequiresEquipment = useJobKindRequiresEquipment(kind);

  return useMemo(() => {
    function buildMissingEquipmentDependencies() {
      if (!kindRequiresEquipment) {
        return [];
      }

      switch (craneSelectorMode) {
        case 'lifts':
          return favoriteConfigurationETag ? [] : [['field.equipment.craneSelectorConfiguration', { ns: 'craneSelector' }] as const];
        case 'manual':
          return manualFavorite ? [] : [['field.equipment.manualBoomConfiguration', { ns: 'craneSelector' }] as const];
        default:
          return _never(craneSelectorMode);
      }
    }

    const missingDependencies = [
      ...(arrivalDate ? [] : [['field.project.date.arrival', { ns: 'serviceCall' }] as const]),
      ...(assignedClient ? [] : [['details.section.client', { ns: 'serviceCall' }] as const]),
      ...(dispatchBranch ? [] : [['field.project.branch.dispatchBranch', { ns: 'serviceCall' }] as const]),
      ...buildMissingEquipmentDependencies(),
      ...(natureOfWork ? [] : [['field.project.work.natureOfWork', { ns: 'serviceCall' }] as const]),
      ...(natureOfWorkSubCategory ? [] : [['field.project.work.natureOfWorkSubCategory', { ns: 'serviceCall' }] as const]),
      ...(worksiteOverridesLocation?.address ? [] : [['field.address', { ns: 'worksite' }] as const]),
      ...(worksiteOverridesName ? [] : [['field.name', { ns: 'worksite' }] as const]),
      ...((craneSelectorMode === 'manual' && vehicleIds != null && !!vehicleIds.length) || craneSelectorMode !== 'manual'
        ? []
        : [['field.equipment.model', { ns: 'serviceCall' }] as const]),
    ];

    return missingDependencies.length > 0 ? missingDependencies : null;
  }, [
    arrivalDate,
    assignedClient,
    craneSelectorMode,
    dispatchBranch,
    favoriteConfigurationETag,
    kindRequiresEquipment,
    manualFavorite,
    natureOfWork,
    natureOfWorkSubCategory,
    vehicleIds,
    worksiteOverridesLocation?.address,
    worksiteOverridesName,
  ]);
}
