/** A type guard returning whether the provided argument is a `string`. */
export function isString(v: unknown): v is string {
  return typeof v === 'string';
}

/**
 * Strongly typed capitalization.
 * @param str
 */
export function capitalize<S extends string>(str: S): Capitalize<S> {
  // Split first and remaining chars without destroying Unicode surrogate pairs
  // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split#description
  const [firstChar, ...rest] = [...str];
  return `${(firstChar ?? '').toUpperCase()}${rest.join('')}` as Capitalize<S>;
}

/**
 * Convert a string into the ts query syntax for full text search
 * @param str
 * @param wordSeparator The tsquery word join parameter to use, & = AND, | = OR, <-> = Followed by
 */
export function convertToTsQuery(str: string | null, wordSeparator: '&' | '|' | '<->' = '&'): string | null {
  if (str === null || str.trim().length === 0) {
    return null;
  }

  return str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '') // remove all accents
    .replace(/'/g, "''") // single quote is a special character in tsquery. they need to be escaped or balanced
    .replace(/\\/g, '\\\\') // backslash is treated as a special character even if it doesn't do anything special, it needs to be escaped
    .split(' ') // split by word
    .filter((s) => s.trim().length !== 0) // filter out part of the array that are only spaces
    .map((s) => `'${s}':*`) // add the start with syntax
    .join(` ${wordSeparator} `);
}

export function isBase64(str: string): boolean {
  const reg = /^(?:[A-Za-z0-9+/]{4})*(?:|[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
  return reg.test(str);
}

// fixme does not work with the new id format
/**
 * @deprecated
 * @param str
 */
export function isGraphqlId(str: string): boolean {
  if (!str) return false;
  return isBase64(str);
}

export function toCamelCase(input: string): string {
  return input
    .replace(/^\w|[A-Z]|\b\w/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}
