import { useEffect, useMemo } from 'react';
import graphql from 'babel-plugin-relay/macro';
import { useFragment } from 'react-relay';
import { createSharedStateKey, useSharedState } from '../common/utils/sharedState';
import { SetValueFn } from '../common/utils/forms';
import { CostLine, useFieldCostLinesCollection } from './costLines/CostLinesFields';
import { useOperations, useOperationsRelay } from '../AppSharedState';
import { jobSharedStateContext } from './JobSharedState';
import { useEffectEvent } from '../common/utils/effectUtils';
import { castCostLineKind } from '../__enums__/CostLineKind';
import { asBillingCodeCategory } from '../__enums__/BillingCodeCategory';
import { Price } from '../common/utils/price';
import { nanoid } from 'nanoid';
import { flagNew, flagRemoved } from '../common/utils/patchable';
import { useCancellableSubscription } from '../common/hooks/useCancellableSubscription';
import { useCraneSelectorFavorite } from './JobEquipment.CraneSelector.Favorite';
import {
  useFieldArrivalDateRead,
  useFieldAssignedWorksiteRead,
  useFieldWorksiteOverridesLocationRead,
  useFieldWorksiteOverridesNameRead,
} from './fields/ProjectBaseFields';
import { useFieldAssignedClientRead } from './fields/ClientBaseFields';
import { useFieldDispatchBranchRead, useFieldNatureOfWorkRead } from './fields/SaleProjectFields';
import { AutomaticCostLineEquipmentInput } from './__generated__/useSalesRate_useCostLineSalesRateQuery.graphql';
import { useFieldBranchToWorksiteDistanceRead } from './fields/SaleEquipmentFields';
import { useInstantCalculator_useSalesRateInputFragment$key } from './__generated__/useInstantCalculator_useSalesRateInputFragment.graphql';
import { usePreviousValue } from '../common/hooks/usePreviousValue';
import { useSnackbar } from 'notistack';
import { useAmbientTranslation } from '../common/hooks/useAmbientTranslation';
import { useInstantCalculatorFragment$key } from './__generated__/useInstantCalculatorFragment.graphql';
import { useInstantCalculatorQuery } from './__generated__/useInstantCalculatorQuery.graphql';
import {
  useInstantCalculator_useApplyInstantCalculatorFragment$data,
  useInstantCalculator_useApplyInstantCalculatorFragment$key,
} from './__generated__/useInstantCalculator_useApplyInstantCalculatorFragment.graphql';

export const APPLY_INSTANT_CALCULATOR_OPERATION_KEY = 'Apply_InstantCalculator';
export const applyInstantCalculatorTokenKey = createSharedStateKey<unknown>(() => null);

export function useInstantCalculator($key: useInstantCalculatorFragment$key | null | undefined, disabled: boolean) {
  const { t } = useAmbientTranslation();
  const [_, setSubscription] = useCancellableSubscription();
  const [applyInstantCalculatorToken] = useSharedState(jobSharedStateContext, applyInstantCalculatorTokenKey);
  const prevApplyInstantCalculatorToken = usePreviousValue(applyInstantCalculatorToken);

  const [, setApplyInstantCalculator$key] = useSharedState(jobSharedStateContext, applyInstantCalculator$keyKey);

  const fetchQuery = useOperationsRelay(APPLY_INSTANT_CALCULATOR_OPERATION_KEY);

  const $data = useFragment(
    graphql`
      fragment useInstantCalculatorFragment on ISale {
        ...useInstantCalculator_useSalesRateInputFragment
        costsBase {
          ...CostLinesFields_CostLineCollectionFragment
        }
        equipment {
          ...SaleEquipmentFields_BranchToWorksiteDistanceFragment
        }
      }
    `,
    $key,
  );

  const salesRateInput = useSalesRateInput($data);

  const { setCostLines } = useFieldCostLinesCollection($data?.costsBase, disabled);
  const { branchToWorksiteDistance } = useFieldBranchToWorksiteDistanceRead($data?.equipment);
  const { enqueueSnackbar } = useSnackbar();
  const fetchInstantCalculator = useEffectEvent(() => {
    const query = graphql`
      query useInstantCalculatorQuery($salesRatesInput: CalculatorSalesRatesInput!, $branchToWorksiteDistance: Length!) {
        ...useInstantCalculator_useApplyInstantCalculatorFragment
          @arguments(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance)
        createInstantCalculatorCostLines(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance) {
          id
        }
      }
    `;

    if (disabled || !branchToWorksiteDistance || !salesRateInput) {
      return;
    }
    setSubscription(
      fetchQuery<useInstantCalculatorQuery>(
        query,
        {
          salesRatesInput: salesRateInput,
          branchToWorksiteDistance: branchToWorksiteDistance,
        },
        [
          (result) => {
            enqueueSnackbar(
              t('alert.costLinesAdded', {
                count: result?.createInstantCalculatorCostLines.length ?? 0,
              }),
            );
            setApplyInstantCalculator$key(result);
          },
        ],
      ).subscribe({}),
    );
  });

  useApplyInstantCalculator(setCostLines);

  useEffect(() => {
    if (prevApplyInstantCalculatorToken === applyInstantCalculatorToken) {
      return;
    }
    if (applyInstantCalculatorToken) {
      fetchInstantCalculator();
    }
  }, [applyInstantCalculatorToken, fetchInstantCalculator, prevApplyInstantCalculatorToken]);
}

const applyInstantCalculator$keyKey = createSharedStateKey<useInstantCalculator_useApplyInstantCalculatorFragment$key | null | undefined>(
  () => null,
);

function useApplyInstantCalculator(setCostLines: SetValueFn<CostLine[]>) {
  const { endOperation } = useOperations(APPLY_INSTANT_CALCULATOR_OPERATION_KEY);
  const [$key] = useSharedState(jobSharedStateContext, applyInstantCalculator$keyKey);

  const $data = useFragment(
    graphql`
      fragment useInstantCalculator_useApplyInstantCalculatorFragment on Query
      @argumentDefinitions(salesRatesInput: { type: "CalculatorSalesRatesInput!" }, branchToWorksiteDistance: { type: "Length!" }) {
        createInstantCalculatorCostLines(salesRatesInput: $salesRatesInput, branchToWorksiteDistance: $branchToWorksiteDistance)
          @required(action: THROW) {
          id
          kind
          craneIndex
          billingCode @required(action: THROW) {
            id
            code
            subCode
            label
            billingSection {
              shortDescription
            }
          }
          billingCodeCategory
          isFractionAllowed
          isFixedQuantity
          defaultQuantity
          requireWorkForceType
          salesRateResult {
            canEditRate
            value {
              isAnnualContract
              isFlexiblePrice
              minimumQuantity
              price
              createdAt
            }
            error {
              code
              description
            }
          }
          workForceType {
            id
            label
            code
          }
          quantity
          rate
          billable
        }
      }
    `,
    $key,
  );

  const updateInstantCalculator = useEffectEvent(
    (instantCalculatorCostLines: useInstantCalculator_useApplyInstantCalculatorFragment$data['createInstantCalculatorCostLines']) => {
      const newCostLines = instantCalculatorCostLines.map(
        ({ quantity, rate, billable, billingCodeCategory, defaultQuantity, kind, salesRateResult, ...rest }) => {
          const costLine: CostLine = {
            ...rest,
            kind: castCostLineKind(kind),
            billingCodeCategory: asBillingCodeCategory(billingCodeCategory),
            defaultQuantity: defaultQuantity ?? null,
            quantity: quantity ?? null,
            rate: Price.fromApi(rate),
            billable: billable ?? null,
            salesRateResult: salesRateResult
              ? {
                  etag: nanoid(),
                  canEditRate: salesRateResult.canEditRate,
                  value: salesRateResult.value ?? null,
                  error: salesRateResult.error ?? null,
                }
              : null,
          };

          return flagNew(costLine);
        },
      );

      setCostLines((prevState) => [
        ...prevState.map((costLine) => {
          if (costLine.kind !== 'instantCalculator') {
            return costLine;
          }

          return flagRemoved(costLine);
        }),
        ...newCostLines,
      ]);
      endOperation();
    },
  );

  useEffect(() => {
    if (!$data || !$key) {
      return;
    }
    updateInstantCalculator($data.createInstantCalculatorCostLines);
  }, [$data, $key, updateInstantCalculator]);
}

function useSalesRateInput($key: useInstantCalculator_useSalesRateInputFragment$key | null | undefined) {
  const $data = useFragment(
    graphql`
      fragment useInstantCalculator_useSalesRateInputFragment on ISale {
        clientBase {
          ...ClientBaseFields_useFieldAssignedClientFragment
        }
        projectBase {
          ...ProjectBaseFields_ArrivalDateFragment @arguments(isCopy: false)
          ...ProjectBaseFields_AssignedWorksiteFragment
          assignedWorksiteInfo {
            ...ProjectBaseFields_WorksiteOverrides_NameFragment
            ...ProjectBaseFields_WorksiteOverrides_LocationFragment
          }
        }
        project {
          ...SaleProjectFields_NatureOfWorkFragment
          ...SaleProjectFields_DispatchBranchFragment
        }
        equipmentBase {
          craneSelector {
            ...JobEquipment_useCraneSelectorFavoriteFragment
          }
        }
      }
    `,
    $key,
  );

  const { favorite } = useCraneSelectorFavorite($data?.equipmentBase.craneSelector, false);
  const { arrivalDate } = useFieldArrivalDateRead($data?.projectBase);
  const { assignedClient } = useFieldAssignedClientRead($data?.clientBase);
  const { dispatchBranch } = useFieldDispatchBranchRead($data?.project);
  const { assignedWorksite } = useFieldAssignedWorksiteRead($data?.projectBase);
  const { worksiteOverridesName } = useFieldWorksiteOverridesNameRead($data?.projectBase.assignedWorksiteInfo);
  const { worksiteOverridesLocation } = useFieldWorksiteOverridesLocationRead($data?.projectBase.assignedWorksiteInfo);
  const { natureOfWork } = useFieldNatureOfWorkRead($data?.project);

  return useMemo(() => {
    if (
      !(
        favorite?.boomConfiguration.id &&
        assignedClient &&
        (assignedWorksite || (!!worksiteOverridesName && !!worksiteOverridesLocation.address)) &&
        natureOfWork &&
        arrivalDate &&
        arrivalDate.isValid &&
        dispatchBranch
      )
    ) {
      return null;
    }

    const parsedArrivalDate = arrivalDate.toJSON();
    if (!parsedArrivalDate) return null;

    const equipments: Array<AutomaticCostLineEquipmentInput | null> = [
      {
        boomConfigurationId: favorite.boomConfiguration.id,
        vehicleId: favorite.vehicleId?.key ?? null,
      },
      ...(favorite.additionalCranes.map((ac) =>
        ac.deletedAt || !ac.boomConfiguration?.id
          ? null
          : {
              boomConfigurationId: ac.boomConfiguration.id,
              vehicleId: null,
            },
      ) ?? []),
    ];

    return {
      equipments: equipments,
      arrivalDate: parsedArrivalDate,
      clientId: assignedClient.id,
      dispatchBranchId: dispatchBranch.id,
      worksiteId: assignedWorksite?.id && assignedWorksite.id !== 'new' ? assignedWorksite.id : null,
      natureOfWorkCode: natureOfWork.code,
    };
  }, [
    arrivalDate,
    assignedClient,
    assignedWorksite,
    dispatchBranch,
    favorite?.additionalCranes,
    favorite?.boomConfiguration.id,
    favorite?.vehicleId?.key,
    natureOfWork,
    worksiteOverridesLocation.address,
    worksiteOverridesName,
  ]);
}
